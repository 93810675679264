import type { MetaFunction, DataFunctionArgs } from "@remix-run/node";
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useMatches,
  useRouteError } from
"@remix-run/react";
import type { HeaderProps } from "./components/skeleton";
import { Header, Main, skeletonStylesheet } from "./components/skeleton";
import tailwind from "./tailwind.css";
import { InternalLink, linkClassName } from "./components/link";

export type Handle = HeaderProps;

export const meta: MetaFunction = (args) => {
  const { data, location } = args;
  return [
  { charset: "utf-8" },
  { title: "Brehoni" },
  { name: "viewport", content: "width=device-width,initial-scale=1" },
  { name: "theme-color", content: "#ffffff" },
  { property: "og:url", content: data + location.pathname }];

};

export function loader({ request }: DataFunctionArgs) {
  const url = new URL(request.url);
  return url.origin;
}

function Html({
  title,
  hero,
  children


}: {children: React.ReactNode;} & Handle) {
  return (
    <html lang="cs">
      <head>
        <Meta />

        <link rel="icon" href="/favicon/favicon2.svg" />
        <link
          rel="mask-icon"
          href="/favicon/apple-mask-icon2.svg"
          color="#000000" />

        <link rel="apple-touch-icon" href="/favicon/favicon2.png" />
        <link rel="stylesheet" href={skeletonStylesheet} />
        <link rel="stylesheet" href={tailwind} />

        <Links />
      </head>
      <body>
        <Header title={title} hero={hero} />
        <Main>{children}</Main>
        <ScrollRestoration />

        <Scripts />
        <LiveReload />
        <script
          type="module"
          dangerouslySetInnerHTML={{
            __html: `let w=new WeakMap();customElements.define("o-bf",class extends HTMLElement{
              static observedAttributes=["uscate"]
              constructor() {super();w.set(this, this.attachShadow({mode: 'closed'}))}
              attributeChangedCallback(a,b,v) {
                v = Array.from(v,(c,i)=>String.fromCharCode((c.charCodeAt(0)+63-i)%95+32)).join("").split("|")
                w.get(this).innerHTML = \`<style>a{color: #0000ee}</style><a href="\${v[0]}" class="${linkClassName}">\${v[1]}</a>\`
              }
            })`
          }} />

      </body>
    </html>);

}

export default function App() {
  const matches = useMatches();
  const handles: readonly Handle[] = (matches.
  map((v) => (v.data as any)?.handle ?? v.handle).
  filter(Boolean).
  reverse() as any);

  const title = handles.find((m) => m.title)?.title;
  const hero = handles.find((m) => m.hero)?.hero;
  return (
    <Html title={title} hero={hero}>
      <Outlet />
    </Html>);

}

export function ErrorBoundary() {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return <FourOhFour />;
    }
    return (
      <Html title="Něco se pokazilo">
        <div className="flex h-96 flex-col items-center justify-center">
          <h1 className="text-3xl font-bold">Aj, chyba!</h1>
          <p className="mb-8 mt-4 text-xl">{error.status}</p>
        </div>
      </Html>);

  }

  return (
    <Html title="Něco se pokazilo">
      <div className="flex h-96 flex-col items-center justify-center">
        <h1 className="text-3xl font-bold">Aj, chyba!</h1>
      </div>
    </Html>);

}

function FourOhFour() {
  return (
    <Html>
      <div className="prose mx-auto flex flex-col items-center justify-center">
        <h1 className="text-3xl font-bold">404</h1>
        <p className="text-xl">Stránka nenalezena, asi jí sežral Surtr</p>
        <img
          src="https://brehoni.cz/image/IYUHJzzk6oWqVx5riFinOvPiRUpRZu6AHq5Q1nrGeJ8/v/article.webp"
          alt="" />

        <p className="mb-16 text-lg">
          Můžeš se podívat na{" "}
          <InternalLink to="/">domovskou stránku</InternalLink>, tu snad
          nesežral.
        </p>
      </div>
    </Html>);

}