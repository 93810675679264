import { Link, NavLink as RemixNavLink, useLocation } from "@remix-run/react";
import type { PropsWithChildren } from "react";
import React, { useEffect, useId } from "react";
import type { VariantImageData } from "./variant-image";
import { VariantImage } from "./variant-image";
import menu from "./menu.svg";
import share from "./share.svg";
import { ExternalLink } from "./link";
import atom from "./a-tom-logo.svg";
import { FullWidthSection } from "./typography";
export { default as skeletonStylesheet } from "./skeleton.css";

export type HeaderProps = {
  title?: string;
  hero?: VariantImageData;
};

export function Header({ title, hero }: HeaderProps) {
  const id = useId();
  const location = useLocation();
  useEffect(() => {
    const el = (document.getElementById(id) as HTMLInputElement);
    if (!el) return;
    el.checked = false;
  }, [id, location]);
  return (
    <>
      <header className="skeleton">
        <input type="checkbox" id={id} />
        <div>
          <Link to="/" className="flex items-center text-xl">
            <img
              src="/favicon/favicon3.svg"
              className="mx-3 h-10 w-10"
              alt="" />

            <div>TOM Brehoni</div>
          </Link>
          <label htmlFor={id}>
            <svg viewBox="0 0 24 24" width="48">
              {/*from https://iconic.app/*/}
              <use className="menu" href={menu + "#menu"} />
              <use className="close" href={menu + "#close"} />
            </svg>
          </label>
        </div>
        <nav
          className="
            relative grid
            grid-cols-[repeat(auto-fit,minmax(10rem,1fr))]
            px-[70px] text-lg
            font-bold
            uppercase
          ">







          <RemixNavLink to="/" className="absolute p-[10px]">
            <img src="/favicon/favicon3.svg" width={50} height={50} alt="" />
          </RemixNavLink>
          <NavLink to="/o-nas">O nás</NavLink>
          <NavLink to="/zajimavosti">Zajímavosti</NavLink>
          <NavLink to="/probehlo">Minulé akce</NavLink>
          <NavLink to="/akce">Nadcházející akce</NavLink>
          <NavLink to="/letni-tabor">Letní tábor</NavLink>
          <div className="absolute right-2">
            <ExternalLink to="https://www.a-tom.cz/">
              <img src={atom} className="-mt-[2px] h-[72px]" alt="" />
            </ExternalLink>
          </div>
        </nav>
        {hero || title ?
        <MaybeOriginalLink
          to={hero?.prefix?.replace(/\/[^/]+$/, "/original")}
          className={"hero" + (title ? " bg-brehoni-green" : "")}>

            {hero ?
          <>
                {title ?
            <div className="hero-title">
                    <div>{title}</div>
                  </div> :
            null}
                <VariantImage data={hero} width="100%" />
              </> :
          title ?
          <div className="title">{title}</div> :
          null}
          </MaybeOriginalLink> :
        null}
      </header>
    </>);

}

function MaybeOriginalLink({
  children,
  to,
  ...rest
}: PropsWithChildren<{to?: string;className?: string;}>) {
  return (
    <div {...rest} className={`${rest.className ?? ""} group relative`}>
      {children}
      {to ?
      <a
        target="_blank"
        rel="noreferrer"
        href={to}
        className="
            invisible absolute
            top-4 right-4 rounded-md bg-black bg-opacity-80 p-1 text-gray-300 hover:bg-gray-800
            hover:bg-opacity-100
            hover:text-white group-hover:visible
          "





        title="Zobrazit v plném rozlišení">

          <svg viewBox="0 0 24 24" width="24">
            {/*from https://iconic.app/*/}
            <use href={share + "#share"} />
          </svg>
        </a> :
      null}
    </div>);

}

export function Main({ children }: {children: React.ReactNode;}) {
  return (
    <>
      <main className="skeleton-main">{children}</main>
      <FullWidthSection>
        <footer>
          2022 – 2024 pro Brehony vytvořila{" "}
          <ExternalLink to="https://codewitchbella.com">
            <span className="text-black">Isabella Skořepová</span>
          </ExternalLink>
        </footer>
      </FullWidthSection>
    </>);

}

function NavLink({ to, children }: {to: string;children: React.ReactNode;}) {
  return (
    <RemixNavLink
      to={to}
      className={(v) => `
        ${v.isActive ? "bg-brehoni-blue-bg text-brehoni-blue" : ""}
        flex h-[70px] items-center justify-center px-2 text-center
      `}>

      {children}
    </RemixNavLink>);

}